// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles

import "tailwindcss/base.css"
import "tailwindcss/components.css"
import "tailwindcss/utilities.css"

import "./src/style.css"
// Highlighting for code blocks
import "prismjs/themes/prism.css"


import "./src/css/markdown.css"